.i_am{
    display: block;
   
}

.i_am li{
    color:#fff;
   padding-top: 20px;
   
   list-style-type:none;
   font-size: 20px;
}