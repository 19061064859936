
*{
  margin: 0;
  padding: 0;
  
}

.main{
  width:100%;
  height: 100vh;
}

video{
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.content{
   position: absolute;
   width:100%;
   height: 100%;
   top:0;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items:center;
   color: white;
   padding:10px
 
}


.content h1{
  color:#fff;
  font-size: 60px;
  line-height: 80px;
  text-align: center;

}

.content h4{
  color:#fff;
  text-align: center;
  font-size: 22px;
}










.header_text_container{
    position: relative;
    top:-430px;
}

.header_text_container h1{
    text-align: center;
    color:#fff;
    font-size:50px;
   
}

.header_text_container h4{
    text-align: center;
    color:#fff;
    font-size:28px;
}

.first_pane_div p{
    font-size: 32px;
line-height: 48px;
}

.second_pane_div h1{
    font-size:30px;
    line-height: 40px;
}
.border{
    border: 0.1px solid #ededed;
    border-radius: 30px;
    padding: 30px;
}

.border-orange{
    border-radius: 30px;
    padding: 30px;
    
    border: 0.1px solid #F7981D;
 
}

.etech_img{
    width:400px
}

.icon{
    color: #fff !important
}

.program_menu{
    display: block;
}

.program_menu li{
    padding: 0;
    list-style: none;
    color:#fff;
    font-size: 20px;
}

.logoStyle{
  width: 120px;
}






/* Small phones to small tablets: from 481px to 767px */
@media only screen and (max-width: 767px) {



 

    .orange-btn {
        background-color: #F7981D;
        padding: 10px 17px;
        border-radius: 30px;
        color: #000;
        font-weight: 600;
      }

      .ghost-btn {
        border: 2px solid #F7981D;
        padding: 10px 20px;
        border-radius: 30px;
        color: #fff;
        font-weight: 600;
      }

      .header_text_container h1 {
        text-align: center;
        color: #fff;
        font-size: 30px;
      }

      .first_pane_div{
        position: relative;
        top:-130px
      }

      .first_pane_div p {
        font-size: 21px;
        line-height: 48px;
      }

      .modalBtn{
        position: relative;
left: 40px;
padding: 20px;
      }

      .logoStyle {
        max-width: 240px;
        padding: 20px;
      }
    
}



/* Small phones: from 0 to 480px */
@media only screen and (max-width: 480px) {

 
  
  video {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }

  .content h1 {
    color: #fff;
    font-size: 30px;
    line-height: 42px;
    text-align: center;
  }

  .header_Text_Cont{
    position: relative;
    top: -22px;
  }
  

 

}