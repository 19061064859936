.logoStyle{
    max-width: 240px;
  }
  





  
    #menu ul
    {
        list-style-type:none;
    }
  
  
    #menu ul li a
    {
  
      font-family: 'Lexend Deca', sans-serif;
        padding:12px 15px;
        display:block;
        color:#fff;
        font-size: 12px;
        text-decoration:none;
        margin-top: -7px;
      
    }
  
  
  
        #menu ul li:hover > ul {
  
          left: 162px;
  
          -webkit-transition: left 200ms ease-in;
  
          -moz-transition: left 200ms ease-in;
  
          -ms-transition: left 200ms ease-in;
  
          transition: left 200ms ease-in;
  
      }
  
  
      #menu ul li > ul {
  
          position: absolute;
  
          background-color: #333;
  
          top: 0;
  
          left: -200px;
  
          min-width: 200px;
  
          z-index: -1;
  
          height: 100%;
  
          -webkit-transition: left 200ms ease-in;
  
          -moz-transition: left 200ms ease-in;
  
          -ms-transition: left 200ms ease-in;
  
          transition: left 200ms ease-in;
  
      }
  
  
      #menu ul li > ul li a:hover
  
      {
  
          background-color:#2e2e2e;
      
  
      }
  




      

  nav {
    margin: 0 auto;
  
    padding: 10px 0;
  }
  nav ul {
    list-style: none;
    text-align: left;
    
  font-family: 'Lexend Deca', sans-serif  !important;
  }
  nav ul li {
    display: flex;
    
  font-family: 'Lexend Deca', sans-serif !important;
    
  }
  nav ul li a {
    font-size: 14px;
    list-style: none;
    text-decoration: none;
   
    letter-spacing: 1px;
    color: #fff !important;
    text-transform: none !important;
    font-family: 'Lexend Deca', sans-serif !important;
    font-weight: 400;
  
    margin-top: px;
  }
  nav ul li a,
  nav ul li a:after,
  nav ul li a:before {
    transition: all 0.5s;
    
  }
  nav ul li a:hover {
    color: ;
    list-style: none !important;
    text-decoration: none;
  }
  
  /* Keyframes */
  @-webkit-keyframes fill {
    0% {
      width: 0%;
      height: 1px;
    }
    50% {
      width: 100%;
      height: 1px;
    }
    100% {
      width: 100%;
      height: 100%;
      background: #fff;
    }
  }
  




/* stroke */
nav.stroke ul li a,
nav.fill ul li a {
  position: relative;
  list-style: none !important;
}
nav.stroke ul li a:after,
nav.fill ul li a:after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 0%;
  content: ".";
  text-decoration: none;
  color: transparent;
  background: #fff;
  height: 1px;
 

}
nav.stroke ul li a:hover:after {
  width: 100%;
}

nav.fill ul li a {
  transition: all 2s;
}

nav.fill ul li a:after {
  text-align: left;
  content: ".";
  margin: 0;
  opacity: 0;
}
nav.fill ul li a:hover {
  color: #fff;
  z-index: 1;
}
nav.fill ul li a:hover:after {
  z-index: -10;
  animation: fill 1s forwards;
  -webkit-animation: fill 1s forwards;
  -moz-animation: fill 1s forwards;
  opacity: 1;
}



.uk-navbar-dropdown {
    display: none;
    position: absolute;
    z-index: 1020;
    box-sizing: border-box;
    width: 220px;
    
    padding: 25px;
    background: #000 !important;
    color: #666;
    box-shadow: 0 5px 12px rgb(0 0 0 / 15%);
  }
  
  
  /***Drop down Menu**/
  .dropDown li {
    padding-bottom: 10px;
  }

  .headerBar {
    opacity: rgba(0, 0, 0, 0.84)!important;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    padding-top: 0px;
}

.uk-navbar-container:not(.uk-navbar-transparent) {
  background: transparent !important;
}
 

/*MOBILE MENU STYLES*/

.mobileMenuBar{

width:340px !important;
}




/*VERTICAL MENU*/
nav.vertical{
  position:relative;
 
}

/* ALL UL */
nav.vertical ul{
  list-style: none;
}
/* ALL LI */
nav.vertical li{
  position:relative;
  width:auto
}
/* ALL A */
nav.vertical a{
  display:block;
  color:#eee;
  text-decoration:none;
  padding:10px 5px;
  transition:0.2s;
  
}
/* ALL A HOVER */
nav.vertical li:hover > a{
  background:#778;
  width: 180px
}

/* INNER UL HIDE */
nav.vertical ul ul{
  background:rgba(0,0,0,0.1);
  padding-left:20px;
  transition: max-height 0.2s ease-out;
  max-height:0;
  overflow:hidden;
}
/* INNER UL SHOW */
nav.vertical li:hover > ul{
  max-height:500px;
  transition: max-height 0.25s ease-in;
}









