.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body{
  background-color: #14171D;
}

.logoStyle{
  width:120px
}


p{
  font-family: 'Lexend Deca', sans-serif;
  font-size:16px;
  font-weight: 400 !important;
}


h1{
  font-family: 'Lexend Deca', sans-serif;
  font-weight: 800;
 
}

.orange{
  color: #F7981D;
}
.orange-btn{
  background-color: #F7981D;
  padding: 20px 40px;
  border-radius: 30px;
  color:#000;
  font-weight: 600;
}

.orange-btn:hover{
  
  background-color: #87D5F1;
  color:#fff;
  text-decoration: none;
}


.ghost-btn{
  border:2px solid  #F7981D;
  padding: 20px 40px;
  border-radius: 30px;
  color:#fff;
  font-weight: 600;
}

.ghost-btn:hover{
  border:2px solid  #87D5F1;
  padding: 20px 40px;
  border-radius: 30px;
  color:#fff;
  font-weight: 600;
  text-decoration: none;
}

.light{
  color:#fff
}

.foooterLogo{
  width:80px
}